/**======================================================================
=========================================================================
Template Name: Able Pro Admin Template
Author: Phoenixcoded
supports: https://phoenixcoded.ticksy.com
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */


// Theme Font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugin/perfect-scrollbar";

// main framework
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

// ng module
@import "react-module/react-module";

// bootstrap
@import "~bootstrap/scss/bootstrap";


// icon
@import "themes/font/next-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
@import "themes/font/flaticon";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";


// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/layouts/menu-react";
@import "layout-dark";
@import "layout-rtl";

// Dashboard Widgets
@import "themes/dashboard/widget";

// custom plugin
@import "themes/plugin/sweatalert";
@import "themes/plugin/lightbox";
@import "themes/plugin/notification";
@import "themes/plugin/pnotify";
@import "themes/plugin/bar-rating";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/tags-input";
@import "themes/plugin/maxlength";
@import "themes/plugin/wizard";
@import "themes/plugin/select2";
@import "themes/plugin/data-tables";

// Charts
@import "themes/plugin/highcharts";
@import "themes/plugin/peitychart";


// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/task-board";
@import "themes/pages/masonry-gallery";

// custom
@import "themes/custom";

.pcoded-inner-content {
}

.form-control{
  border-bottom: 1px solid #ced4da !important;
}

.swal2-container.swal2-shown{
  z-index: 10000;
}
.my-icon-remady {
  margin-left: -19px;
  margin-right: 14px;
  margin-top: -13px;  
}

.latest-update-box{
  .col{
    position: relative;
    padding-right: 40px;
    .map-action{
      position: absolute;
      right: 0;
      top: 2px; 
    }
  }
}

.latest-update-box{
  .col{
    h6{
      line-height: 24px;
      margin-bottom: 5px;
    }
    .text-muted{
      clear: both;
      font-size: 12px;
    }
    .badge{
      float: left;
      margin-bottom: 8px;
    }
  }
}

.user-profile-list table tbody tr:hover a, .user-profile-list table tbody tr:hover .badge-pill{
  color: #fff;
}

.user-profile-list table tbody tr:hover .badge-pill{
  background: rgba(255,255,255,0.1);
}

.dashboard-overview {
  .col{
    margin-bottom: 30px;
  }
}

.dashboard-overview {
  .card{
    height: 100%;
    margin-bottom: 0;
  }
}

.filter-bar {
  .Employee-field{
    width:170px;
  }
}

.mw-45{
  min-width: 45px;
}

.clock-in-wrapper{
  .w-100{
    margin-top: -15px;
  }
}
.my-cal-wrapper {
  div{
    z-index: 100;
  }
}

.my-spinner{
  position: absolute;
  left: 48%;
  top: 20px;
  z-index: 99;

  &.my-spinner-right{
    left: inherit;
    right: 12px;
    top:12px;
  }
}